import axios from 'axios';
import auth from './auth';

class Members {

    constructor(options) {

        const defaults = {
            containerSelector: '#members-container',
            toolbarGuestTemplateSelector: '#members-toolbar-guest',
            toolbarAuthenticatedTemplateSelector: '#members-toolbar-authenticated',
            modalSelector: '#members-login-modal',
            loginTemplateSelector: '#members-login-template',
            registrationTemplateSelector: '#members-registration-template',
            passwordResetRequestTemplateSelector: '#members-password-reset-request-template',
            passwordResetCompleteTemplateSelector: '#members-password-reset-complete-template',
            sidebarSelector: '#account-panel'
        };
        this.params = Object.assign({}, defaults, options || {});

        this.container = document.querySelector(this.params.containerSelector);
        this.toolbarGuestTemplate = document.querySelector(this.params.toolbarGuestTemplateSelector);
        this.toolbarAuthenticatedTemplate = document.querySelector(this.params.toolbarAuthenticatedTemplateSelector);
        this.loginTemplate = document.querySelector(this.params.loginTemplateSelector);
        this.registrationTemplate = document.querySelector(this.params.registrationTemplateSelector);
        this.passwordResetRequestTemplate = document.querySelector(this.params.passwordResetRequestTemplateSelector);
        this.passwordResetCompleteTemplate = document.querySelector(this.params.passwordResetCompleteTemplateSelector);
        this.modal = document.querySelector(this.params.modalSelector);

        this.start();
    }

    async start() {
        await auth.getProfile();
        this.addRoutes();
        this.render();
    }

    addRoutes() {

        window.addEventListener('hashchange', (event) => {
            if (window.location.hash === '#login') {
                this.renderLoginView();
                this.modal.showModal();
            }
            else if (window.location.hash === '#register') {
                this.renderRegistrationView();
                this.modal.showModal();
            }
            else if (window.location.hash === '#reset-password') {
                this.renderPasswordResetRequestView();
                this.modal.showModal();
            }
            else if (window.location.hash === '#reset-password-complete') {
                this.renderPasswordResetCompleteView();
                this.modal.showModal();
            }
        });
    }

    render() {

        if (auth.isLoggedIn) {
            this.renderAuthenticatedToolbarView();
        } else {
            this.renderGuestToolbarView();
        }

        window.addEventListener('DOMContentLoaded', () => {
            if (window.location.hash === '#login') {
                this.renderLoginView();
                this.modal.showModal();
            }
            else if (window.location.hash === '#register') {
                this.renderRegistrationView();
                this.modal.showModal();
            }
            else if (window.location.hash === '#reset-password') {
                this.renderPasswordResetRequestView();
                this.modal.showModal();
            }
            else if (window.location.hash === '#reset-password-complete') {
                this.renderPasswordResetCompleteView();
                this.modal.showModal();
            }
        });

        this.modal.addEventListener('close', (event) => {
            history.pushState({}, document.title, window.location.pathname + window.location.search);
        });

    }

    async register(payload) {

        let response;

        try {
            response = await axios.post('/members?task=member.register&format=json', payload, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        } catch (error) {
            this.displayMessage(error.response.data.message);
            return false;
        }

        this.displayMessage(response.data.message);

        return true;
    }

    renderLoginView() {

        if (!this.loginTemplate || !this.modal) {
            return;
        }

        const clone = this.loginTemplate.content.cloneNode(true);

        // Joomla Login button
        const loginButton = clone.querySelector('#members-login-button');
        const loginForm = clone.querySelector('form');
        loginForm.addEventListener('submit', async (event) => {
            event.preventDefault();
            loginButton.disabled = true;
            this.cleanMessages();
            const email = loginForm.querySelector('input[name="email"]').value;
            const password = loginForm.querySelector('input[name="password"]').value;
            const result = await auth.login(email, password);
            if (result) {
                this.modal.close();
                this.start();
            } else {
                this.displayMessage(auth.error);
            }
            loginButton.disabled = false;
        });

        // Modal close button
        const modalCloseButton = this.modal.querySelector('#members-close-modal-button');
        modalCloseButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.modal.close();
        });

        this.modal.querySelector('.modal-content').innerHTML = '';
        this.modal.querySelector('.modal-content').appendChild(clone);

        // Activation message
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('activated')) {
            this.displayMessage('Ο Λογαριασμός σας ενεργοποιήθηκε. Μπορείτε να συνδεθείτε με το email και τον κωδικό πρόσβασης που επιλέξατε κατά την εγγραφή σας.');
        }

    }

    renderRegistrationView() {

        if (!this.registrationTemplate || !this.modal) {
            return;
        }

        const clone = this.registrationTemplate.content.cloneNode(true);

        // Joomla Register button
        const registerButton = clone.querySelector('#members-register-button');
        const registerForm = clone.querySelector('form');
        registerForm.addEventListener('submit', async (event) => {
            event.preventDefault();
            registerButton.disabled = true;
            this.cleanMessages();

            const payload = {};
            const inputs = registerForm.querySelectorAll('input');
            const regex = /\jform+\[(\w+)\]/;
            inputs.forEach(input => {
                const [fullName, name] = input.name.match(regex);
                const value = input.value;
                payload[name] = value;
            });

            payload['recaptcha'] = registerForm.querySelector('#jform_captcha textarea').value;
            payload['newsletter'] = registerForm.querySelector('#jform_newsletter').checked ? 1 : 0;

            const result = await this.register(payload);

            if (typeof window.JoomlaInitReCaptchaInvisible === 'function') {
                const captcha = registerForm.querySelector('#jform_captcha');
                grecaptcha.reset(captcha.getAttribute('data-recaptcha-widget-id'));
                grecaptcha.execute(captcha.getAttribute('data-recaptcha-widget-id'));
            }

            registerButton.disabled = false;
        });

        // Modal close button
        const modalCloseButton = this.modal.querySelector('#members-close-modal-button');
        modalCloseButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.modal.close();
        });

        this.modal.querySelector('.modal-content').innerHTML = '';
        this.modal.querySelector('.modal-content').appendChild(clone);

        if (typeof window.JoomlaInitReCaptchaInvisible === 'function') {
            window.grecaptcha.ready(() => {
                try {
                    window.JoomlaInitReCaptchaInvisible();
                } catch (error) { }
            });
        }
    }


    renderGuestToolbarView() {
        if (!this.toolbarGuestTemplate) {
            return;
        }

        const clone = this.toolbarGuestTemplate.content.cloneNode(true);
        const openLoginModalButton = clone.getElementById('members-open-login-modal-button');
        openLoginModalButton.addEventListener('click', (event) => {
            window.sessionStorage.setItem('membersLoginReturn', window.location);
        });
        this.container.innerHTML = '';
        this.container.appendChild(clone);
    }

    renderAuthenticatedToolbarView() {

        if (!this.toolbarAuthenticatedTemplate) {
            return;
        }

        const clone = this.toolbarAuthenticatedTemplate.content.cloneNode(true);
        clone.getElementById('members-name').textContent = auth.profile.name;
        clone.getElementById('members-avatar-letters').textContent = this.getAvatarLetters(auth.profile.name);
        const logoutButton = clone.querySelector('#members-logout-button');
        logoutButton.addEventListener('click', (event) => {
            event.preventDefault();
            auth.logout();
            this.render();
        });

        this.sidebar = clone.querySelector(this.params.sidebarSelector);

        const openSidebarButton = clone.querySelector('#members-open-sidebar-button');
        openSidebarButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.sidebar.showModal();
        });

        const closeSidebarButton = clone.querySelector('#members-close-sidebar-button');
        closeSidebarButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.sidebar.close();
        });

        this.container.innerHTML = '';
        this.container.appendChild(clone);
    }

    renderPasswordResetRequestView() {

        if (!this.passwordResetRequestTemplate || !this.modal) {
            return;
        }

        const clone = this.passwordResetRequestTemplate.content.cloneNode(true);

        // Submit button
        const submitButton = clone.querySelector('button[type="submit"]');
        const form = clone.querySelector('form');
        form.addEventListener('submit', async (event) => {
            event.preventDefault();
            submitButton.disabled = true;
            this.cleanMessages();
            const email = form.querySelector('input[name="jform[email]"]').value;
            const captcha = form.querySelector('#jform_captcha textarea').value;

            const result = await auth.resetPasswordRequest(email, captcha);
            if (result) {
                window.location.hash = '#reset-password-complete';
            } else {
                this.displayMessage(auth.error);
            }

            if (typeof window.JoomlaInitReCaptchaInvisible === 'function') {
                const captcha = form.querySelector('#jform_captcha');
                grecaptcha.reset(captcha.getAttribute('data-recaptcha-widget-id'));
                grecaptcha.execute(captcha.getAttribute('data-recaptcha-widget-id'));
            }

            submitButton.disabled = false;
        });

        // Modal close button
        const modalCloseButton = this.modal.querySelector('#members-close-modal-button');
        modalCloseButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.modal.close();
        });

        this.modal.querySelector('.modal-content').innerHTML = '';
        this.modal.querySelector('.modal-content').appendChild(clone);

        if (typeof window.JoomlaInitReCaptchaInvisible === 'function') {
            window.grecaptcha.ready(() => {
                try {
                    window.JoomlaInitReCaptchaInvisible();
                } catch (error) { }
            });
        }
    }

    renderPasswordResetCompleteView() {

        if (!this.passwordResetCompleteTemplate || !this.modal) {
            return;
        }

        const clone = this.passwordResetCompleteTemplate.content.cloneNode(true);

        // Submit button
        const submitButton = clone.querySelector('button[type="submit"]');
        const form = clone.querySelector('form');
        form.addEventListener('submit', async (event) => {
            event.preventDefault();
            submitButton.disabled = true;
            this.cleanMessages();
            const email = form.querySelector('input[name="jform[email]"]').value;
            const token = form.querySelector('input[name="jform[token]"]').value;
            const password1 = form.querySelector('input[name="jform[password1]"]').value;
            const password2 = form.querySelector('input[name="jform[password2]"]').value;

            const result = await auth.resetPasswordComplete(email, token, password1, password2);
            if (result) {
                this.displayMessage(auth.message);
            } else {
                this.displayMessage(auth.error);
            }
            submitButton.disabled = false;
        });

        // Modal close button
        const modalCloseButton = this.modal.querySelector('#members-close-modal-button');
        modalCloseButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.modal.close();
        });

        this.modal.querySelector('.modal-content').innerHTML = '';
        this.modal.querySelector('.modal-content').appendChild(clone);
    }


    getAvatarLetters(name) {

        let result = '';
        const parts = name.split(' ');
        if (parts[0]) {
            result += parts[0].charAt(0);
        }

        if (parts[1]) {
            result += parts[1].charAt(0);
        }

        result = result.toUpperCase();

        return result;
    }


    displayMessage(message) {
        const messagesContainer = this.modal.querySelector('#members-messages');
        if (!messagesContainer) {
            return;
        }
        messagesContainer.textContent = message;
    }

    cleanMessages() {
        const messagesContainer = this.modal.querySelector('#members-messages');
        if (!messagesContainer) {
            return;
        }
        messagesContainer.textContent = '';
    }


}

export {
    Members as
        default
};
